import styled, { css } from 'styled-components';

export const SlideWrapper = styled.div`
    ${({ theme }) => css`
        min-width: 18rem;

        @media ${theme.breakpoints.large.media} {
            min-width: 29.7rem;
        }
    `}
`;
