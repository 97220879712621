import React from 'react';
import htmlParser from 'html-react-parser';
import dynamic from 'next/dynamic';
import cmsParser from 'Common/util/cmsParser';
import { v4 as uuidv4 } from 'uuid';
import { sanitizePhoneNumber } from 'Common/util/string';
import NextImage from 'next/image';

import defaultProfileImage from 'Public/images/cards/profile/default.png';

import type { ImageCaptionProps } from 'Components/ImageCaption';
import type { QuoteBlockProps } from 'Components/QuoteBlock';
import type { VideoProps } from 'Components/Video';
import type { AttentionBoxProps } from 'Components/AttentionBox';
import type { AccordionProps } from 'Components/Accordion';
import type { ListProps } from 'Components/List';
import type { TableProps } from 'Components/Table';
import type { IframeGeckoProps } from 'Components/IframeGecko';
import type { UnibuddyMainProps } from 'Components/Unibuddy';
import type { UnibuddyCarouselProps } from 'Components/Unibuddy/Carousel';
import type { ImageAndTextProps } from 'Components/TextBlock/ImageAndText';
import type { ProfileCardProps } from 'Components/Card/Profile';
import type { ContactInfoSupportBlockProps } from 'Components/SupportBlock/ContactInfo';
import type { CarouselProps, Slide } from 'Components/Carousel';
import Video from 'Components/Video';

import type { LinkCardsProps } from 'Modules/Cards/Link';
import type { MiniCardsProps } from 'Modules/Cards/Mini';
import type { BrandBannerProps } from 'Modules/Banner/Brand';
import type { PromoPointTefAwardProps } from 'Modules/PromoPoint/TefAward';

import { AccordionHeading } from 'Templates/Flexible/Landing/Landing.styled';
import { SlideWrapper } from 'Common/styles/Flexible/Flexible.styled';

export type ContentComposerProps = {
    text?: React.ReactNode;
    image?: {
        imageCaption: ImageCaptionProps;
        columnWidth: number;
    };
    quote?: QuoteBlockProps;
    textImageTwoColumns?: ImageAndTextProps;
    video?: {
        heading: string;
        embed: VideoProps;
    };
    attentionBox?: AttentionBoxProps;
    accordion?: AccordionProps[];
    listTwoColumns?: {
        listOneHeading: string;
        listOne: ListProps;
        listTwoHeading: string;
        listTwo: ListProps;
    };
    table?: TableProps;
    promoCards?: LinkCardsProps;
    meetTheTeam?: {
        heading?: string;
        cards: MiniCardsProps;
    };
    brandBanner?: BrandBannerProps;
    geckoForm?: IframeGeckoProps;
    tefAward?: PromoPointTefAwardProps;
    unibuddyMain?: UnibuddyMainProps;
    unibuddyCarousel?: UnibuddyCarouselProps;
    anchor?: string;
    flexibleAcademicStaffProfile?: ProfileCardProps & { heading: string };
    contactInformation?: { contacts: ContactInfoSupportBlockProps[]; heading: string };
    carousel?: CarouselProps & { heading: string };
};

const FlexibleContent = (items: any[]): ContentComposerProps[] => {
    const content: ContentComposerProps[] = [];

    items.map((item, index) => {
        //for carousel
        const combinedSlides = (images?: any[], videos?: any[]): Slide[] => [
            ...(videos || []).map(item => ({
                element: (
                    <SlideWrapper>
                        <Video {...cmsParser.transcriptEmbed(item)} />
                    </SlideWrapper>
                ),
                id: uuidv4()
            })),
            ...(images || []).map(item => ({
                element: (
                    <SlideWrapper>
                        <NextImage
                            src={cmsParser.image(item).src}
                            alt={cmsParser.image(item).alt ? cmsParser.image(item).alt : ''}
                            width={1920}
                            height={1080}
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </SlideWrapper>
                ),
                id: uuidv4()
            }))
        ];
        switch (item.type) {
            case 'flexibleCarousel':
                content.push({
                    carousel: {
                        heading: item.value.heading && item.value.heading,
                        slides: combinedSlides(
                            item.value.image && item.value.image,
                            item.value.video && item.value.video
                        ),
                        id: uuidv4()
                    }
                });
                break;
            case 'text':
                content.push({ text: htmlParser(item.value) });
                break;
            case 'imageCaption':
                content.push({
                    image: {
                        imageCaption: {
                            image: cmsParser.image(item.value.image, '/cms'),
                            caption: item.value.image.caption
                        },
                        columnWidth: parseInt(item.value.columnWidth)
                    }
                });
                break;
            case 'quote':
                content.push({
                    quote: {
                        children: item.value.quote.text,
                        cite: {
                            author: item.value.quote.source,
                            citation: item.value.sourceCitation,
                            url: item.value.sourceURL
                        },
                        quoteColorLeft: `#${item.value.leftQuoteColour}`,
                        quoteColorRight: `#${item.value.rightQuoteColour}`
                    }
                });
                break;
            case 'textImageTwoColumns':
                content.push({
                    textImageTwoColumns: {
                        description: htmlParser(item.value.text),
                        imageCaption: {
                            image: cmsParser.image(item.value.imageCaption, '/cms'),
                            caption: item.value.imageCaption.caption
                        },
                        imageLeftOfDescription:
                            item.value.layout === 'Image left, text right' ? true : false,
                        imageColumnWidth: item.value.imageColumnWidth
                            ? parseInt(item.value.imageColumnWidth)
                            : 6,
                        offsetImage: item.value.includeColumnGap
                    }
                });
                break;
            case 'youTubeVideo':
                content.push({
                    video: {
                        heading: item.value.title,
                        embed: cmsParser.video(item.value)
                    }
                });
                break;
            case 'attentionBox':
                content.push({
                    attentionBox: {
                        icon: item.value.iconName
                            ? dynamic(() =>
                                  import('@solent-university/solent-icons').then(
                                      icons => icons[item.value.iconName.name]
                                  )
                              )
                            : undefined,
                        text: item.value.text
                    }
                });
                break;
            case 'accordion':
                content.push({
                    accordion: item.value.map((item, index) => ({
                        id: index,
                        heading: <AccordionHeading>{item.title}</AccordionHeading>,
                        headingType: 'h3',
                        button: {
                            ariaLabel: `View ${item.title}`
                        },
                        children: htmlParser(item.content),
                        variant: 'tertiary',
                        isBackgroundSameColour: true
                    }))
                });
                break;
            case 'listTwoColumns':
                content.push({
                    listTwoColumns: {
                        listOneHeading: item.value.leftColumnList.label,
                        listOne: {
                            items: item.value.leftColumnList.item.map((item, index) => ({
                                id: index,
                                text: item
                            }))
                        },
                        listTwoHeading: item.value.rightColumnList.label,
                        listTwo: {
                            items: item.value.rightColumnList.item.map((item, index) => ({
                                id: index,
                                text: item
                            }))
                        }
                    }
                });
                break;
            case 'table':
                content.push({
                    table: {
                        heading: item.value.tableHeading,
                        headingType: 'h2',
                        tableHeadings: item.value.headings,
                        tableRows: item.value.data.map(row =>
                            row.type === 'tableRowTextOnly' ? row.value.map(cell => cell.text) : []
                        )
                    }
                });
                break;
            case 'promoCards':
                content.push({
                    promoCards: {
                        heading: item.value.heading && item.value.heading,
                        cards: item.value.squareGridPromoCard.map((card, index) => ({
                            link: cmsParser.link(card),
                            id: `link-card-${index}`,
                            backgroundColor: cmsParser.getThemeColour(card.backgroundColor),
                            textColor: cmsParser.getThemeColour(card.textColor)
                        }))
                    }
                });
                break;
            case 'meetTheTeam':
                content.push({
                    meetTheTeam: {
                        heading: item.value.heading && item.value.heading,
                        cards: {
                            items: item.value.courseTeamMember
                                .filter(item => item.profile?.staffMember && item.profile.sys?.uri)
                                .map(({ profile, role }) => {
                                    const fullName = `${
                                        profile.staffMember.person.preferredName ||
                                        profile.staffMember.person.forename
                                    } ${profile.staffMember.person.surname}`;

                                    return {
                                        id: profile.sys.id,
                                        heading: fullName,
                                        furtherDetails: role || profile.staffMember.jobTitle,
                                        image: profile.staffMember.person.picture
                                            ? cmsParser.image(profile.staffMember.person.picture)
                                            : { src: defaultProfileImage },
                                        link: {
                                            href: profile.sys.uri,
                                            ariaLabel: `View the profile of ${fullName}`
                                        }
                                    };
                                })
                        }
                    }
                });
                break;
            case 'brandBanner':
                content.push({
                    brandBanner: {
                        heading: item.value.heading,
                        text: item.value.description,
                        link: {
                            ...cmsParser.linkButton(item.value.buttonCTA),
                            variant: item.value.buttonVariant ? item.value.buttonVariant : 'primary'
                        },
                        backgroundColor: cmsParser.getThemeColour(item.value.backgroundColor),
                        textColor: cmsParser.getThemeColour(item.value.textColor)
                    }
                });
                break;
            case 'geckoForm':
                content.push({
                    geckoForm: {
                        id: item.value.geckoScriptId,
                        url: item.value.url,
                        heading: item.value.header,
                        subHeading: item.value.subHeading
                    }
                });
                break;
            case 'tefAward':
                content.push({
                    tefAward: {
                        heading: item.value.promoPointHeading,
                        text: item.value.promoPointDescription,
                        link: item.value.promoPointButton && {
                            ...cmsParser.linkButton(item.value.promoPointButton)
                        },
                        image: cmsParser.image(item.value.tefImage)
                    }
                });
                break;
            case 'uniBuddyMain':
                content.push({
                    unibuddyMain: {
                        iframe: {
                            src: item.value.iframeSrc
                        },
                        heading: item.value.heading,
                        id: `flexible-unibuddy-main-${index}`
                    }
                });
                break;
            case 'uniBuddyCarousel':
                content.push({
                    unibuddyCarousel: {
                        heading: item.value.heading,
                        id: `flexible-unibuddy-carousel-${index}`
                    }
                });
                break;
            case 'anchorLink':
                content.push({ anchor: item.value.anchor });
                break;
            case 'flexibleAcademicStaffProfile': {
                const profile = item.value.academicStaffProfile.staffMember;
                content.push({
                    flexibleAcademicStaffProfile: {
                        heading: item.value.heading,
                        firstName: profile.person.forename,
                        lastName: profile.person.surname,
                        jobTitle: profile.jobTitle,
                        info: item.value.academicStaffProfile.department.name,
                        email:
                            profile.person.email && !profile.person.hideEmail
                                ? {
                                      label: profile.person.email,
                                      ariaLabel: `Email ${profile.person.email}`,
                                      href: `mailto:${profile.person.email}`
                                  }
                                : undefined,
                        phone: profile.person.phoneNumber
                            ? {
                                  label: profile.person.phoneNumber,
                                  ariaLabel: `Call ${profile.person.phoneNumber}`,
                                  href: `tel:${sanitizePhoneNumber(profile.person.phoneNumber)}`
                              }
                            : undefined,
                        profileLink: cmsParser.link({
                            ...item.value.academicStaffProfile,
                            label: `View profile`,
                            ariaLabel: `Click to view profile`
                        }),
                        image: profile.person.picture
                            ? {
                                  ...cmsParser.image(profile.person.picture)
                              }
                            : undefined
                    }
                });
                break;
            }
            case 'contacts':
                content.push({
                    contactInformation: {
                        contacts: item.value.contacts.map(contact => {
                            //TODO: Remove one day - temporarily handle both single items & array items - future will just be array for phone & email
                            const parseField = field =>
                                Array.isArray(field)
                                    ? field.map(cmsParser.link)
                                    : field?.label
                                    ? [cmsParser.link(field)]
                                    : undefined;
                            return {
                                heading: contact.heading,
                                headingType: 'h3',
                                icons: contact.useIcons,
                                contacts: [
                                    {
                                        information: contact.informationText
                                            ? contact.informationText
                                            : undefined,
                                        phone: parseField(contact.phone),
                                        email: parseField(contact.email),
                                        url: contact.url?.label
                                            ? cmsParser.link(contact.url)
                                            : undefined
                                    }
                                ]
                            };
                        }),
                        heading: item.value.heading
                    }
                });
                break;
            default:
                return {};
        }
    });

    return content;
};

export default FlexibleContent;
