import htmlParser, { HTMLReactParserOptions } from 'html-react-parser';
import cmsParser from 'Common/util/cmsParser';

import ArrowLink from 'Elements/Link/Arrow';
import type { VoucherProps } from 'Elements/Voucher';
import type { ArrowLinkButtonProps } from 'Elements/Button/Arrow';

import type { ImageAndTextProps } from 'Components/TextBlock/ImageAndText';

import type { KeyStatsFlexibleProps } from 'Modules/KeyStats/Flexible';
import type { LinkCardsProps } from 'Modules/Cards/Link';

export type CampaignComposerProps = {
    textWithKeyFacts?: KeyStatsFlexibleProps;
    textImageTwoColumns?: ImageAndTextProps;
    travelVoucher?: {
        text: React.ReactNode;
        button?: ArrowLinkButtonProps;
        voucher: VoucherProps;
    };
    promoCards?: LinkCardsProps;
};

const parseShortcode = (content: string) => {
    const regex = /\[arrowLink href="([^"]+)" label="([^"]+)" ariaLabel="([^"]+)"\]/g;

    return content.replace(regex, (_, href, label, ariaLabel) => {
        return `<ArrowLink href="${href}" label="${label}" ariaLabel="${ariaLabel}" .>`;
    });
};

const extractShortcode: HTMLReactParserOptions = {
    replace: node => {
        if (node.type === 'tag' && node.name === 'arrowlink') {
            const { href, label, ariaLabel } = node.attribs as {
                href: string;
                label: string;
                ariaLabel: string;
            };

            return <ArrowLink href={href} label={label} ariaLabel={ariaLabel} />;
        }
    }
};

const RecruitmentCampaign = (pageElements: any[]): CampaignComposerProps[] => {
    const content: CampaignComposerProps[] = [];

    pageElements.map((item, index) => {
        switch (item.type) {
            case 'textWithKeyFacts':
                content.push({
                    textWithKeyFacts: {
                        sectionID: item.value.inPageNavigationID
                            ? item.value.inPageNavigationID
                            : `keyStats-${index}`,
                        heading: htmlParser(item.value.heading),
                        headingType: item.value.headingType,
                        headingColor:
                            item.value.headingColor &&
                            cmsParser.getThemeColour(item.value.headingColor),
                        backgroundColor:
                            item.value.backgroundColor &&
                            cmsParser.getThemeColour(item.value.backgroundColor),
                        textColor:
                            item.value.textColor && cmsParser.getThemeColour(item.value.textColor),
                        link: item.value.linkCTA.label
                            ? {
                                  ...cmsParser.link(item.value.linkCTA),
                                  variant:
                                      item.value.backgroundColor === 'Tint grey - #E8E9EA'
                                          ? 'default'
                                          : 'secondary'
                              }
                            : undefined,
                        text: htmlParser(item.value.text),
                        keyStats: item.value.keyFact.map((item, index) => ({
                            id: `keyStat-${index}`,
                            statValue: item.number,
                            stat: item.fact.replace('{{', '{{{').replace('}}', '}}}'),
                            description: item.description,
                            textColor: item?.textColor && cmsParser.getThemeColour(item.textColor),
                            backgroundColor:
                                item?.backgroundColour &&
                                cmsParser.getThemeColour(item.backgroundColour)
                        }))
                    }
                });
                break;
            case 'textImageTwoColumns':
                content.push({
                    textImageTwoColumns: {
                        description: htmlParser(parseShortcode(item.value.text), extractShortcode),
                        imageCaption: {
                            image: cmsParser.image(item.value.imageCaption, '/cms'),
                            caption: item.value.imageCaption.caption
                        },
                        imageLeftOfDescription:
                            item.value.layout === 'Image left, text right' ? true : false,
                        imageColumnWidth: item.value.imageColumnWidth
                            ? parseInt(item.value.imageColumnWidth)
                            : 6,
                        textVerticalAlignment: 'center',
                        offsetImage: item.value.includeColumnGap
                    }
                });
                break;
            case 'travelVoucherBanner':
                content.push({
                    travelVoucher: {
                        text: htmlParser(item.value.text),
                        button: item.value.button
                            ? {
                                  ...cmsParser.linkButton(item.value.button),
                                  variant: 'accentTwoTextWhiteBg'
                              }
                            : undefined,
                        voucher: {
                            text: item.value.voucherText,
                            value: item.value.voucherValue
                        }
                    }
                });
                break;
            case 'squareGridPromoCardsWrapper':
                content.push({
                    promoCards: {
                        heading: item.value.heading && item.value.heading,
                        cards: item.value.squareGridPromoCard.map((card, index) => ({
                            link: cmsParser.link(card),
                            id: `link-card-${index}`,
                            backgroundColor: cmsParser.getThemeColour(card.backgroundColor),
                            textColor: cmsParser.getThemeColour(card.textColor)
                        }))
                    }
                });
                break;
            default:
                return {};
        }
    });

    return content;
};

export default RecruitmentCampaign;
