import React from 'react';
import htmlParser from 'html-react-parser';
import cmsParser from 'Common/util/cmsParser';
import dynamic from 'next/dynamic';
import NextImage from 'next/image';
import { v4 as uuidv4 } from 'uuid';

import defaultProfileImage from 'Public/images/cards/profile/default.png';

import { InformationBannerProps } from 'Modules/Banner/Information';
import { GeneralPromoPointProps } from 'Modules/PromoPoint/General';
import { CourseSearchProps } from 'Modules/PromoPoint/CourseSearch';
import { ContactUsProps } from 'Modules/OpenDays/Section/ContactUs';
import { InPageNavigationProps } from 'Modules/InPageNavigation';
import { InformationGridProps } from 'Modules/InformationGrid';
import { KeyStatsFlexibleProps } from 'Modules/KeyStats/Flexible';
import { GridNavigationProps } from 'Modules/GridNavigation';
import { VirtualTourProps } from 'Modules/VirtualTour';
import { LinkCardsProps } from 'Modules/Cards/Link';
import { BrandProps } from 'Modules/Banner/Brand';
import { MiniCardsProps } from 'Modules/Cards/Mini';
import { PromoPointTefAwardProps } from 'Modules/PromoPoint/TefAward';

import { BorderlessCardProps } from 'Components/Card/Borderless';
import type { ImageCaptionProps } from 'Components/ImageCaption';
import type { QuoteBlockProps } from 'Components/QuoteBlock';
import type { AccordionProps } from 'Components/Accordion';
import { PageDividerProps } from 'Components/PageDivider';
import type { LineListProps } from 'Components/List/LineList';
import type { TableProps } from 'Components/Table';
import type { VideoProps } from 'Components/Video';
import { IframeGeckoProps } from 'Components/IframeGecko';
import { UnibuddyMainProps } from 'Components/Unibuddy';
import { UnibuddyCarouselProps } from 'Components/Unibuddy/Carousel';
import type { CarouselProps, Slide } from 'Components/Carousel';
import Video from 'Components/Video';

import { LinkProps } from 'Elements/Link';
import { TextBlockWaveProps } from 'Components/TextBlock/Wave';

import { AccordionHeading } from 'Templates/Flexible/Landing/Landing.styled';
import { SlideWrapper } from 'Common/styles/Flexible/Flexible.styled';

export type LandingComposerProps = {
    inPageAnchorId?: string;
    textBlockWithImage?: {
        heading?: string;
        text: React.ReactNode;
        link?: LinkProps;
        imageCaption: ImageCaptionProps;
        order?: React.CSSProperties['order'];
    };
    textBlockWithVideo?: {
        heading?: string;
        text: React.ReactNode;
        link?: LinkProps;
        video: VideoProps;
        order?: React.CSSProperties['order'];
    };
    twoColumnTextWithAccordion?: {
        heading: string;
        text: React.ReactNode;
        link?: LinkProps;
        accordion: AccordionProps[];
        order?: React.CSSProperties['order'];
    };
    textBlockWithBulletPointList?: {
        heading?: string;
        text: React.ReactNode;
        link?: LinkProps;
        list: LineListProps;
        order?: React.CSSProperties['order'];
    };
    twoColumnTextBlock?: {
        headingColumnOne?: string;
        textColumnOne: React.ReactNode;
        linkColumnOne?: LinkProps;
        headingColumnTwo?: string;
        textColumnTwo: React.ReactNode;
        linkColumnTwo?: LinkProps;
        backgroundColour?: string;
    };
    meetTheTeam?: {
        heading?: string;
        cards: MiniCardsProps;
    };
    twoVideoSection?: {
        heading?: string;
        leftColumnVideo: VideoProps;
        rightColumnVideo: VideoProps;
    };
    singleVideo?: {
        heading?: string;
        embed: VideoProps;
    };
    iconList?: {
        list: LineListProps & { heading?: string };
    };
    generalPromoPointWithWave?: GeneralPromoPointProps;
    textBlockWithCourseSearch?: CourseSearchProps;
    quoteFlexibleLanding?: QuoteBlockProps;
    introBlock?: InformationBannerProps;
    textWithKeyFacts?: KeyStatsFlexibleProps;
    informationBannerWithImage?: InformationBannerProps;
    contactUs?: ContactUsProps;
    tripleIconPromo?: InformationGridProps;
    pageDivider?: PageDividerProps;
    accordion?: { heading?: string; items: AccordionProps[] };
    table?: TableProps;
    bannerBrand?: BrandProps;
    squareGridPromoCardsWrapper?: LinkCardsProps;
    inPageNavigation?: InPageNavigationProps;
    flexibleGridNavigation?: GridNavigationProps & { heading?: string };
    virtualTour?: VirtualTourProps;
    textBlockWave?: TextBlockWaveProps;
    iconPromoPointWave?: ContactUsProps;
    geckoForm?: IframeGeckoProps;
    tefAward?: PromoPointTefAwardProps;
    unibuddyMain?: UnibuddyMainProps;
    unibuddyCarousel?: UnibuddyCarouselProps;
    threeFourCardFeatureBlock?: {
        heading?: string;
        cards: BorderlessCardProps[];
    };
    callToAction?: LinkProps;
    carousel?: CarouselProps & { heading: string };
};

const FlexibleLanding = (pageElements: any[], courses?: any[]): LandingComposerProps[] => {
    const content: LandingComposerProps[] = [];

    pageElements.map((item, index) => {
        switch (item.type) {
            case 'flexibleCarousel': {
                const combinedSlides = (images?: any[], videos?: any[]): Slide[] => [
                    ...(videos || []).map(item => ({
                        element: (
                            <SlideWrapper>
                                <Video {...cmsParser.transcriptEmbed(item)} />
                            </SlideWrapper>
                        ),
                        id: uuidv4()
                    })),
                    ...(images || []).map(item => ({
                        element: (
                            <SlideWrapper>
                                <NextImage
                                    src={cmsParser.image(item).src}
                                    alt={cmsParser.image(item).alt ? cmsParser.image(item).alt : ''}
                                    width={1920}
                                    height={1080}
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                />
                            </SlideWrapper>
                        ),
                        id: uuidv4()
                    }))
                ];

                content.push({
                    carousel: {
                        heading: item.value.heading && item.value.heading,
                        slides: combinedSlides(
                            item.value.image && item.value.image,
                            item.value.video && item.value.video
                        ),
                        id: uuidv4()
                    }
                });
                break;
            }
            case 'threeFourCardFeatureBlock':
                content.push({
                    threeFourCardFeatureBlock: {
                        heading: item.value.heading && item.value.heading,
                        cards: item.value.borderlessCard.map(card => ({
                            heading: card.heading && card.heading,
                            text: htmlParser(card.text),
                            image: cmsParser.image(card.image),
                            link: card.link.label && cmsParser.link(card.link)
                        }))
                    }
                });
                break;
            case 'inPageAnchorId':
                content.push({
                    inPageAnchorId: item.value.id
                });
                break;
            case 'callToAction':
                content.push({
                    callToAction: cmsParser.arrowLink(item.value)
                });
                break;
            case 'iconList':
                content.push({
                    iconList: {
                        list: {
                            heading: item.value.heading && item.value.heading,
                            items: item.value.iconList.map((item, index) => ({
                                id: item.iconId || `linelist-${index}`,
                                text: item.text,
                                icon: dynamic(() =>
                                    import('@solent-university/solent-icons').then(
                                        icons => icons[item.icon.name]
                                    )
                                ),
                                iconColour: '#1D2136'
                            }))
                        }
                    }
                });
                break;
            case 'flexibleGridNavigation':
                content.push({
                    flexibleGridNavigation: {
                        heading: item.value.heading && item.value.heading,
                        items: item.value.composer.map((card, index) => ({
                            ...cmsParser.link({ label: card.value.heading, ...card.value }),
                            id: `gridNavigationCard-${index}`,
                            backgroundColour: cmsParser.getThemeColour(card.value.backgroundColor),
                            image: card.value?.image
                                ? cmsParser.image(card.value.image, '/cms')
                                : undefined,
                            variant: cmsParser.getLinkVariant(
                                card.value.linkVariantPicker.linkVariantPicker
                            )
                        }))
                    }
                });
                break;
            case 'textBlockWithImage':
                content.push({
                    textBlockWithImage: {
                        heading: item.value.heading && item.value.heading,
                        text: htmlParser(item.value.bodyText),
                        order:
                            item.value.layout === 'Text on left, image on right'
                                ? 'initial'
                                : item.value.layout === null
                                ? 'initial'
                                : 1,
                        link: item.value.linkCTA.link && cmsParser.arrowLink(item.value.linkCTA),
                        imageCaption: {
                            image: cmsParser.image(item.value.image, '/cms')
                        }
                    }
                });
                break;
            case 'textBlockWithCourseSearch': {
                content.push({
                    textBlockWithCourseSearch: {
                        promoHeading:
                            item.value.rightColumnHeading && item.value.rightColumnHeading,
                        text: item.value.rightColumnText,
                        link:
                            item.value.rightColumnLinkCTA.label !== null
                                ? cmsParser.arrowLink(item.value.rightColumnLinkCTA)
                                : undefined,
                        search: {
                            placeholder: 'Search for a course',
                            search: {
                                href: '/courses?search={{search}}',
                                ariaLabel: 'Submit your course search'
                            },
                            label: 'Course search',
                            fetchResults: (
                                value: string
                            ): { id: string; label: string; href: string }[] =>
                                value === ''
                                    ? []
                                    : courses
                                    ? courses
                                          .filter(
                                              course =>
                                                  item.value.courseSearchDegreeTypes.includes(
                                                      course.sys.contentTypeId
                                                  ) && course.name.toLowerCase().includes(value)
                                          )
                                          .map(course => ({
                                              id: course.sys.id,
                                              label: `${
                                                  course.degreeType ? course.degreeType + ' ' : ''
                                              }${course.name}`,
                                              href: course.sys.uri
                                          }))
                                    : [] // additional ternary to prevent error as courses can be undefined in this instance but case will never run
                        }
                    }
                });
                break;
            }
            case 'twoColumnTextWithAccordion':
                content.push({
                    twoColumnTextWithAccordion: {
                        heading: item.value.heading && item.value.heading,
                        text: htmlParser(item.value.bodyText),
                        link: item.value.linkCTA.link && cmsParser.linkButton(item.value.linkCTA),
                        order:
                            item.value.layout === 'Text on left, accordion on right'
                                ? 'initial'
                                : item.value.layout === null
                                ? 'initial'
                                : 1,
                        accordion: item.value.accordion.map((item, index) => ({
                            id: `twoColumnTextWithAccordion-${index}`,
                            heading: <AccordionHeading>{item.title}</AccordionHeading>,
                            button: {
                                ariaLabel: `View ${item.title}`
                            },
                            variant: 'tertiary',
                            children: htmlParser(item.content),
                            isBackgroundSameColour: true
                        }))
                    }
                });
                break;
            case 'twoColumnTextBlock':
                content.push({
                    twoColumnTextBlock: {
                        headingColumnOne: item.value.headingColumnOne,
                        textColumnOne: htmlParser(item.value.bodyTextColumnOne),
                        linkColumnOne:
                            item.value.linkColumnOne.link &&
                            cmsParser.linkButton(item.value.linkColumnOne),
                        headingColumnTwo: item.value.headingColumnTwo,
                        textColumnTwo: htmlParser(item.value.bodyTextColumnTwo),
                        linkColumnTwo:
                            item.value.linkColumnTwo.link &&
                            cmsParser.linkButton(item.value.linkColumnTwo),
                        backgroundColour: cmsParser.getThemeColour(item.value.backgroundColour)
                    }
                });
                break;
            case 'textBlockWithBulletPointList':
                content.push({
                    textBlockWithBulletPointList: {
                        heading: item.value.heading && item.value.heading,
                        text: htmlParser(item.value.bodyText),
                        order:
                            item.value.layout === 'Text left, bullets right'
                                ? 'initial'
                                : item.value.layout === null
                                ? 'initial'
                                : 1,
                        link: item.value.linkCTA.link && cmsParser.link(item.value.linkCTA),
                        list: {
                            items: item.value.listItem.iconList.map((item, index) => ({
                                id: item.iconId || `text-block-linelist-${index}`,
                                text: item.text,
                                icon: dynamic(() =>
                                    import('@solent-university/solent-icons').then(
                                        icons => icons[item.icon.name]
                                    )
                                ),
                                iconColour: '#1D2136'
                            }))
                        }
                    }
                });
                break;
            case 'textBlockWithVideo':
                content.push({
                    textBlockWithVideo: {
                        heading: item.value.heading && item.value.heading,
                        text: htmlParser(item.value.bodyText),
                        order:
                            item.value.layout === 'Text on left, video on right'
                                ? 'initial'
                                : item.value.layout === null
                                ? 'initial'
                                : 1,
                        link: item.value.linkCTA.link && cmsParser.linkButton(item.value.linkCTA),
                        video: cmsParser.transcriptEmbed(item.value.video)
                    }
                });
                break;
            case 'informationBannerWithImage':
                content.push({
                    informationBannerWithImage: {
                        heading: item.value.heading,
                        children: htmlParser(item.value.bodyText),
                        link: item.value.linkCTA.link && cmsParser.link(item.value.linkCTA),
                        image: cmsParser.image(item.value.image, '/cms'),
                        color: cmsParser.getThemeColour(item.value.color)
                    }
                });
                break;
            case 'quoteFlexibleLanding':
                content.push({
                    quoteFlexibleLanding: {
                        children: item.value.quote.text,
                        cite: {
                            author: item.value.quote.source,
                            url: item.value.sourceURL
                        }
                    }
                });
                break;
            case 'singleVideo':
                content.push({
                    singleVideo: {
                        heading: item.value.heading ? item.value.heading : undefined,
                        embed: cmsParser.transcriptEmbed(item.value)
                    }
                });
                break;
            case 'twoVideoSection':
                content.push({
                    twoVideoSection: {
                        heading: item.value.heading ? item.value.heading : undefined,
                        leftColumnVideo: cmsParser.transcriptEmbed(item.value.leftColumnVideo),
                        rightColumnVideo: cmsParser.transcriptEmbed(item.value.rightColumnVideo)
                    }
                });
                break;
            case 'accordion':
                content.push({
                    accordion: {
                        heading: item.value.heading ? item.value.heading : undefined,
                        items: item.value.map((item, index) => ({
                            id: `accordion-${index}`,
                            heading: <AccordionHeading>{item.title}</AccordionHeading>,
                            button: {
                                ariaLabel: `View ${item.title}`
                            },
                            variant: 'tertiary',
                            children: htmlParser(item.content),
                            isBackgroundSameColour: true
                        }))
                    }
                });
                break;
            case 'table':
                content.push({
                    table: {
                        headingType: 'h2',
                        heading: item.value.title && item.value.title,
                        tableHeadings: item.value.headings.map(heading => heading),
                        tableRows: item.value.data.map(row =>
                            row.type === 'tableRowTextOnly' ? row.value.map(cell => cell.text) : []
                        )
                    }
                });
                break;
            case 'pageDivider':
                content.push({
                    pageDivider: {
                        wave:
                            item.value.waveOrientation &&
                            cmsParser.getWaveOrientation(item.value.waveOrientation),
                        backgroundColor: cmsParser.getThemeColour(
                            item.value.chooseBackgroundColour
                        ),
                        marginTop: `${item.value.marginTop}rem`,
                        marginBottom: `${item.value.marginBottom}rem`
                    }
                });
                break;
            case 'bannerBrand':
                content.push({
                    bannerBrand: {
                        heading: item.value.heading,
                        text: item.value.description,
                        link: {
                            ...cmsParser.linkButton(item.value.buttonCTA),
                            variant: item.value.buttonVariant ? item.value.buttonVariant : 'primary'
                        },
                        backgroundColor: cmsParser.getThemeColour(item.value.backgroundColor),
                        textColor: cmsParser.getThemeColour(item.value.textColor)
                    }
                });
                break;
            case 'introBlock':
                content.push({
                    introBlock: {
                        heading: item.value.heading,
                        children: htmlParser(item.value.bodyText),
                        link: item.value.link.link ? cmsParser.link(item.value.link) : undefined,
                        image: cmsParser.image(item.value.image, '/cms'),
                        color: item.value.color
                    }
                });
                break;
            case 'squareGridPromoCardsWrapper':
                content.push({
                    squareGridPromoCardsWrapper: {
                        heading: item.value.heading && item.value.heading,
                        cards: item.value.squareGridPromoCard.map((card, index) => ({
                            link: cmsParser.link(card),
                            id: `link-card-${index}`,
                            backgroundColor: cmsParser.getThemeColour(card.backgroundColor),
                            textColor: cmsParser.getThemeColour(card.textColor)
                        }))
                    }
                });
                break;
            case 'generalPromoPointWithWave':
                content.push({
                    generalPromoPointWithWave: {
                        heading: item.value.heading,
                        text: item.value.description,
                        link:
                            item.value.buttonCTA.link && cmsParser.linkButton(item.value.buttonCTA),
                        image: item.value.image && cmsParser.image(item.value.image),
                        backgroundColor:
                            item.value.backgroundColour &&
                            cmsParser.getThemeColour(item.value.backgroundColour),
                        textColor:
                            item.value.textColour && cmsParser.getThemeColour(item.value.textColor)
                    }
                });
                break;
            case 'textWithKeyFacts':
                content.push({
                    textWithKeyFacts: {
                        sectionID: item.value.inPageNavigationID
                            ? item.value.inPageNavigationID
                            : `keyStats-${index}`,
                        heading: item.value.heading,
                        headingType: item.value.headingType,
                        headingColor:
                            item.value.headingColor &&
                            cmsParser.getThemeColour(item.value.headingColor),
                        backgroundColor:
                            item.value.backgroundColor &&
                            cmsParser.getThemeColour(item.value.backgroundColor),
                        textColor:
                            item.value.textColor && cmsParser.getThemeColour(item.value.textColor),
                        link: item.value.linkCTA.label
                            ? {
                                  ...cmsParser.link(item.value.linkCTA),
                                  variant:
                                      item.value.backgroundColor === 'Tint grey - #E8E9EA'
                                          ? 'default'
                                          : 'secondary'
                              }
                            : undefined,
                        text: htmlParser(item.value.text),
                        keyStats: item.value.keyFact.map((item, index) => ({
                            id: `keyStat-${index}`,
                            statValue: item.number,
                            stat: item.fact.replace('{{', '{{{').replace('}}', '}}}'),
                            description: item.description,
                            textColor: item?.textColor && cmsParser.getThemeColour(item.textColor),
                            backgroundColor:
                                item?.backgroundColour &&
                                cmsParser.getThemeColour(item.backgroundColour)
                        }))
                    }
                });
                break;
            case 'virtualTour':
                content.push({
                    virtualTour: {
                        button: {
                            ariaLabel: item.value.buttonAriaLabel,
                            label: item.value.buttonLabel
                        },
                        heading: item.value.heading,
                        text: item.value.description,
                        fileSize: item.value.fileSize,
                        embed: {
                            src: item.value.embedCode,
                            title: item.value.embedTitle
                        }
                    }
                });
                break;
            case 'meetTheTeam':
                content.push({
                    meetTheTeam: {
                        heading: item.value.heading && item.value.heading,
                        cards: {
                            items: item.value.courseTeamMember
                                .filter(item => item.profile?.staffMember && item.profile.sys?.uri)
                                .map(({ profile, role }) => {
                                    const fullName = `${
                                        profile.staffMember.person.preferredName ||
                                        profile.staffMember.person.forename
                                    } ${profile.staffMember.person.surname}`;

                                    return {
                                        id: profile.sys.id,
                                        heading: fullName,
                                        furtherDetails: role || profile.staffMember.jobTitle,
                                        image: profile.staffMember.person.picture
                                            ? cmsParser.image(profile.staffMember.person.picture)
                                            : { src: defaultProfileImage },
                                        link: {
                                            href: profile.sys.uri,
                                            ariaLabel: `View the profile of ${fullName}`
                                        }
                                    };
                                })
                        }
                    }
                });
                break;
            case 'textBlockWave':
                content.push({
                    textBlockWave: {
                        heading: item.value.heading && item.value.heading,
                        children: htmlParser(item.value.bodyText),
                        link: item.value.linkCTA.link && cmsParser.arrowLink(item.value.linkCTA),
                        wave: item.value.wave
                    }
                });
                break;
            case 'iconPromoPointWave':
                content.push({
                    iconPromoPointWave: {
                        id: `iconPromoPoint-${index}`,
                        heading: item.value.heading,
                        generalQuestions: {
                            link: cmsParser.linkButton(item.value.linkButton)
                        },
                        icon: dynamic(() =>
                            import('@solent-university/solent-icons').then(
                                icons => icons[item.value.icon.name]
                            )
                        )
                    }
                });
                break;
            case 'geckoForm':
                content.push({
                    geckoForm: {
                        id: item.value.geckoScriptId,
                        url: item.value.url,
                        heading: item.value.header,
                        subHeading: item.value.subHeading
                    }
                });
                break;
            case 'uniBuddyMain':
                content.push({
                    unibuddyMain: {
                        iframe: {
                            src: item.value.iframeSrc
                        },
                        heading: item.value.heading,
                        id: `flexible-unibuddy-main-${index}`
                    }
                });
                break;
            case 'uniBuddyCarousel':
                content.push({
                    unibuddyCarousel: {
                        heading: item.value.heading,
                        id: `flexible-unibuddy-carousel-${index}`
                    }
                });
                break;
            case 'tripleIconPromo':
                content.push({
                    tripleIconPromo: {
                        items: item.value.map(gridItem => ({
                            heading: gridItem.heading,
                            text: gridItem.description,
                            link: gridItem.link && {
                                ...cmsParser.arrowLink(gridItem.link),
                                iconOutline: true
                            },
                            icon: dynamic(() =>
                                import('@solent-university/solent-icons').then(
                                    icons => icons[gridItem.icon.name]
                                )
                            )
                        }))
                    }
                });
                break;
            case 'tefAward':
                content.push({
                    tefAward: {
                        heading: item.value.promoPointHeading,
                        text: item.value.promoPointDescription,
                        link: item.value.promoPointButton && {
                            ...cmsParser.linkButton(item.value.promoPointButton)
                        },
                        image: cmsParser.image(item.value.tefImage)
                    }
                });
                break;
            default:
                return {};
        }
    });

    return content;
};

export default FlexibleLanding;
