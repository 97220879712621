import cmsParser from 'Common/util/cmsParser';
import { ImageAnimatedProps } from 'Components/Card/ImageAnimated';

export type VirtualTourComposerProps = {
    virtualToursCards?: {
        tourCards: ImageAnimatedProps[];
        addPromo: boolean;
    };
};

const VirtualTour = (pageElements: any[]): VirtualTourComposerProps[] => {
    const content: VirtualTourComposerProps[] = [];

    pageElements.map(item => {
        switch (item.type) {
            case 'virtualTours1Card':
            case 'virtualTours2Cards':
            case 'virtualTours3Cards':
            case 'virtualToursCardWithPromo': {
                const cards = item.type === 'virtualTours1Card' ? [item.value] : item.value;
                content.push({
                    virtualToursCards: {
                        tourCards: cards.map(card => ({
                            heading: card.heading,
                            image: cmsParser.image(card.image, '/cms'),
                            link: {
                                id: card.heading.replaceAll(' ', '-'),
                                href: card.linkURL.url,
                                ariaLabel: `Virtual tour of ${card.heading}`,
                                openInNewTab: true
                            }
                        })),
                        addPromo: item.type === 'virtualToursCardWithPromo' ? true : false
                    }
                });
                break;
            }
            default:
                return {};
        }
    });

    return content;
};

export default VirtualTour;
