import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $heading?: string; $backgroundColour?: string }>`
    ${({ theme, $heading, $backgroundColour }) => css`
        padding: 1.5rem 1rem;
        border: ${theme.commonStyles.brandPixel} solid;
        border-color: ${$backgroundColour
            ? $backgroundColour
            : theme.colors.secondary.tints[2].hex};
        border-radius: ${$heading ? '0 0 0.5rem 0.5rem' : ' 0.5rem'};
        background: ${theme.colors.background.hex};

        @media ${theme.breakpoints.medium.media} {
            padding: 1.5rem;
        }

        aside & {
            margin-bottom: 1.5rem;
            padding: 0.75rem;

            @media ${theme.breakpoints.medium.media} {
                padding: 0.75rem;
            }
        }
    `}
`;

export const HeadingWrapper = styled.div<{ $backgroundColour?: string; $colour?: string }>`
    ${({ theme, $backgroundColour, $colour }) => css`
        padding: 1rem 0.5rem;
        border-radius: 0.25rem 0.25rem 0 0;
        text-align: center;
        color: ${$colour ? $colour : theme.colors.secondary.hex};
        background: ${$backgroundColour ? $backgroundColour : theme.colors.accents[5].hex};

        @media ${theme.breakpoints.medium.media} {
            padding: 1rem;
        }
    `}
`;

export const Heading = styled.p`
    margin: 0;
`;
