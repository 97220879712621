import React from 'react';

import { Wrapper, HeadingWrapper, Heading } from './Block.styled';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    heading?: string;
    backgroundColour?: string;
    colour?: string;
    style?: React.CSSProperties;
}

const Block: React.FC<Props> = props => {
    const { children, heading, backgroundColour, colour, style, ...rest } = props;

    return (
        <>
            {heading && (
                <HeadingWrapper $backgroundColour={backgroundColour} $colour={colour}>
                    <Heading>{heading}</Heading>
                </HeadingWrapper>
            )}
            <Wrapper
                $backgroundColour={backgroundColour}
                $heading={heading}
                style={style}
                {...rest}
            >
                {children}
            </Wrapper>
        </>
    );
};

export default Block;
